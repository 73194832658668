import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2a95ec88 = () => interopDefault(import('../pages/ai-reports.vue' /* webpackChunkName: "pages/ai-reports" */))
const _15f6a964 = () => interopDefault(import('../pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _715756f9 = () => interopDefault(import('../pages/boost-all.vue' /* webpackChunkName: "pages/boost-all" */))
const _17e08be8 = () => interopDefault(import('../pages/bug-reports.vue' /* webpackChunkName: "pages/bug-reports" */))
const _dd85bece = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _76808664 = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _76e94903 = () => interopDefault(import('../pages/feedback-funnel/index.vue' /* webpackChunkName: "pages/feedback-funnel/index" */))
const _4733607a = () => interopDefault(import('../pages/ht-alerts.vue' /* webpackChunkName: "pages/ht-alerts" */))
const _105f0557 = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _35db4fd8 = () => interopDefault(import('../pages/journal-edits/index.vue' /* webpackChunkName: "pages/journal-edits/index" */))
const _73529693 = () => interopDefault(import('../pages/lock-logs.vue' /* webpackChunkName: "pages/lock-logs" */))
const _a55b8c2a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2cdf8c78 = () => interopDefault(import('../pages/manager-stats/index.vue' /* webpackChunkName: "pages/manager-stats/index" */))
const _1f593eb2 = () => interopDefault(import('../pages/manuals/index.vue' /* webpackChunkName: "pages/manuals/index" */))
const _245e780a = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _6c40693a = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _2cbddae4 = () => interopDefault(import('../pages/missed-payments/index.vue' /* webpackChunkName: "pages/missed-payments/index" */))
const _7a8015e3 = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _8837f7f8 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _1e70042c = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _59c80105 = () => interopDefault(import('../pages/payment-updates.vue' /* webpackChunkName: "pages/payment-updates" */))
const _2ec829ff = () => interopDefault(import('../pages/pl-stats/index.vue' /* webpackChunkName: "pages/pl-stats/index" */))
const _c64f7caa = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _7c29cd4a = () => interopDefault(import('../pages/profiles/index.vue' /* webpackChunkName: "pages/profiles/index" */))
const _66453751 = () => interopDefault(import('../pages/rejection-categories/index.vue' /* webpackChunkName: "pages/rejection-categories/index" */))
const _3542f654 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _5284ade4 = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _660a1e64 = () => interopDefault(import('../pages/top-triggers/index.vue' /* webpackChunkName: "pages/top-triggers/index" */))
const _4cdaac14 = () => interopDefault(import('../pages/traffic-stats/index.vue' /* webpackChunkName: "pages/traffic-stats/index" */))
const _01510428 = () => interopDefault(import('../pages/triggers/index.vue' /* webpackChunkName: "pages/triggers/index" */))
const _1d7c8068 = () => interopDefault(import('../pages/update-stats/index.vue' /* webpackChunkName: "pages/update-stats/index" */))
const _e94a808a = () => interopDefault(import('../pages/wink-replies/index.vue' /* webpackChunkName: "pages/wink-replies/index" */))
const _be3dcb00 = () => interopDefault(import('../pages/manuals/new.vue' /* webpackChunkName: "pages/manuals/new" */))
const _41d71f1c = () => interopDefault(import('../pages/my-team/add.vue' /* webpackChunkName: "pages/my-team/add" */))
const _0c0e91e8 = () => interopDefault(import('../pages/my-team/pending.vue' /* webpackChunkName: "pages/my-team/pending" */))
const _0fe2cae0 = () => interopDefault(import('../pages/news/CreateNewsModal.vue' /* webpackChunkName: "pages/news/CreateNewsModal" */))
const _7c16eac2 = () => interopDefault(import('../pages/news/EditNewsModal.vue' /* webpackChunkName: "pages/news/EditNewsModal" */))
const _ad92bea6 = () => interopDefault(import('../pages/news/SeenByModal.vue' /* webpackChunkName: "pages/news/SeenByModal" */))
const _b9a7467a = () => interopDefault(import('../pages/profiles/create.vue' /* webpackChunkName: "pages/profiles/create" */))
const _24c69760 = () => interopDefault(import('../pages/profiles/pending.vue' /* webpackChunkName: "pages/profiles/pending" */))
const _5aeca6ea = () => interopDefault(import('../pages/profiles/update-mixin.js' /* webpackChunkName: "pages/profiles/update-mixin" */))
const _0960aedf = () => interopDefault(import('../pages/rejection-categories/new.vue' /* webpackChunkName: "pages/rejection-categories/new" */))
const _565b2a3b = () => interopDefault(import('../pages/triggers/manage.vue' /* webpackChunkName: "pages/triggers/manage" */))
const _8ea7c89e = () => interopDefault(import('../pages/triggers/pending.vue' /* webpackChunkName: "pages/triggers/pending" */))
const _b57621de = () => interopDefault(import('../pages/update-stats/AddNewFeature.vue' /* webpackChunkName: "pages/update-stats/AddNewFeature" */))
const _5f7ee18e = () => interopDefault(import('../pages/activity-logs/_type.vue' /* webpackChunkName: "pages/activity-logs/_type" */))
const _c7af0406 = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _8cd4ecd2 = () => interopDefault(import('../pages/my-team/_operator/index.vue' /* webpackChunkName: "pages/my-team/_operator/index" */))
const _6d8ff41c = () => interopDefault(import('../pages/profile-duplicates/_country.vue' /* webpackChunkName: "pages/profile-duplicates/_country" */))
const _6f113998 = () => interopDefault(import('../pages/profiles/_profile/index.vue' /* webpackChunkName: "pages/profiles/_profile/index" */))
const _b8ead074 = () => interopDefault(import('../pages/profiles/_profileold.vue' /* webpackChunkName: "pages/profiles/_profileold" */))
const _0813a7cf = () => interopDefault(import('../pages/triggers/_trigger.vue' /* webpackChunkName: "pages/triggers/_trigger" */))
const _4ad8ea56 = () => interopDefault(import('../pages/my-team/_operator/edit.vue' /* webpackChunkName: "pages/my-team/_operator/edit" */))
const _bd9f2054 = () => interopDefault(import('../pages/my-team/_operator/lock-logs.vue' /* webpackChunkName: "pages/my-team/_operator/lock-logs" */))
const _e305c922 = () => interopDefault(import('../pages/my-team/_operator/message-list.vue' /* webpackChunkName: "pages/my-team/_operator/message-list" */))
const _453d480d = () => interopDefault(import('../pages/my-team/_operator/profile-list.vue' /* webpackChunkName: "pages/my-team/_operator/profile-list" */))
const _9628c644 = () => interopDefault(import('../pages/my-team/_operator/trigger-list.vue' /* webpackChunkName: "pages/my-team/_operator/trigger-list" */))
const _015229e2 = () => interopDefault(import('../pages/profiles/_profile/photos.vue' /* webpackChunkName: "pages/profiles/_profile/photos" */))
const _38aad258 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ai-reports",
    component: _2a95ec88,
    name: "ai-reports"
  }, {
    path: "/bonus",
    component: _15f6a964,
    name: "bonus"
  }, {
    path: "/boost-all",
    component: _715756f9,
    name: "boost-all"
  }, {
    path: "/bug-reports",
    component: _17e08be8,
    name: "bug-reports"
  }, {
    path: "/dashboard",
    component: _dd85bece,
    name: "dashboard"
  }, {
    path: "/faqs",
    component: _76808664,
    name: "faqs"
  }, {
    path: "/feedback-funnel",
    component: _76e94903,
    name: "feedback-funnel"
  }, {
    path: "/ht-alerts",
    component: _4733607a,
    name: "ht-alerts"
  }, {
    path: "/invoices",
    component: _105f0557,
    name: "invoices"
  }, {
    path: "/journal-edits",
    component: _35db4fd8,
    name: "journal-edits"
  }, {
    path: "/lock-logs",
    component: _73529693,
    name: "lock-logs"
  }, {
    path: "/login",
    component: _a55b8c2a,
    name: "login"
  }, {
    path: "/manager-stats",
    component: _2cdf8c78,
    name: "manager-stats"
  }, {
    path: "/manuals",
    component: _1f593eb2,
    name: "manuals"
  }, {
    path: "/message-reports",
    component: _245e780a,
    name: "message-reports"
  }, {
    path: "/messages",
    component: _6c40693a,
    name: "messages"
  }, {
    path: "/missed-payments",
    component: _2cbddae4,
    name: "missed-payments"
  }, {
    path: "/my-team",
    component: _7a8015e3,
    name: "my-team"
  }, {
    path: "/news",
    component: _8837f7f8,
    name: "news"
  }, {
    path: "/notifications",
    component: _1e70042c,
    name: "notifications"
  }, {
    path: "/payment-updates",
    component: _59c80105,
    name: "payment-updates"
  }, {
    path: "/pl-stats",
    component: _2ec829ff,
    name: "pl-stats"
  }, {
    path: "/profile",
    component: _c64f7caa,
    name: "profile"
  }, {
    path: "/profiles",
    component: _7c29cd4a,
    name: "profiles"
  }, {
    path: "/rejection-categories",
    component: _66453751,
    name: "rejection-categories"
  }, {
    path: "/settings",
    component: _3542f654,
    name: "settings"
  }, {
    path: "/stats",
    component: _5284ade4,
    name: "stats"
  }, {
    path: "/top-triggers",
    component: _660a1e64,
    name: "top-triggers"
  }, {
    path: "/traffic-stats",
    component: _4cdaac14,
    name: "traffic-stats"
  }, {
    path: "/triggers",
    component: _01510428,
    name: "triggers"
  }, {
    path: "/update-stats",
    component: _1d7c8068,
    name: "update-stats"
  }, {
    path: "/wink-replies",
    component: _e94a808a,
    name: "wink-replies"
  }, {
    path: "/manuals/new",
    component: _be3dcb00,
    name: "manuals-new"
  }, {
    path: "/my-team/add",
    component: _41d71f1c,
    name: "my-team-add"
  }, {
    path: "/my-team/pending",
    component: _0c0e91e8,
    name: "my-team-pending"
  }, {
    path: "/news/CreateNewsModal",
    component: _0fe2cae0,
    name: "news-CreateNewsModal"
  }, {
    path: "/news/EditNewsModal",
    component: _7c16eac2,
    name: "news-EditNewsModal"
  }, {
    path: "/news/SeenByModal",
    component: _ad92bea6,
    name: "news-SeenByModal"
  }, {
    path: "/profiles/create",
    component: _b9a7467a,
    name: "profiles-create"
  }, {
    path: "/profiles/pending",
    component: _24c69760,
    name: "profiles-pending"
  }, {
    path: "/profiles/update-mixin",
    component: _5aeca6ea,
    name: "profiles-update-mixin"
  }, {
    path: "/rejection-categories/new",
    component: _0960aedf,
    name: "rejection-categories-new"
  }, {
    path: "/triggers/manage",
    component: _565b2a3b,
    name: "triggers-manage"
  }, {
    path: "/triggers/pending",
    component: _8ea7c89e,
    name: "triggers-pending"
  }, {
    path: "/update-stats/AddNewFeature",
    component: _b57621de,
    name: "update-stats-AddNewFeature"
  }, {
    path: "/activity-logs/:type?",
    component: _5f7ee18e,
    name: "activity-logs-type"
  }, {
    path: "/conversations/:id?",
    component: _c7af0406,
    name: "conversations-id"
  }, {
    path: "/my-team/:operator?",
    component: _8cd4ecd2,
    name: "my-team-operator"
  }, {
    path: "/profile-duplicates/:country?",
    component: _6d8ff41c,
    name: "profile-duplicates-country"
  }, {
    path: "/profiles/:profile",
    component: _6f113998,
    name: "profiles-profile"
  }, {
    path: "/profiles/:profileold",
    component: _b8ead074,
    name: "profiles-profileold"
  }, {
    path: "/triggers/:trigger",
    component: _0813a7cf,
    name: "triggers-trigger"
  }, {
    path: "/my-team/:operator?/edit",
    component: _4ad8ea56,
    name: "my-team-operator-edit"
  }, {
    path: "/my-team/:operator?/lock-logs",
    component: _bd9f2054,
    name: "my-team-operator-lock-logs"
  }, {
    path: "/my-team/:operator?/message-list",
    component: _e305c922,
    name: "my-team-operator-message-list"
  }, {
    path: "/my-team/:operator?/profile-list",
    component: _453d480d,
    name: "my-team-operator-profile-list"
  }, {
    path: "/my-team/:operator?/trigger-list",
    component: _9628c644,
    name: "my-team-operator-trigger-list"
  }, {
    path: "/profiles/:profile/photos",
    component: _015229e2,
    name: "profiles-profile-photos"
  }, {
    path: "/",
    component: _38aad258,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
